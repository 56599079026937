import React, {useState, useEffect} from "react"
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "@StarberryUtils"
import reviews from '../../../images/advice/google-reviews.png';
import reviews02 from '../../../images/advice/google-reviews2.png';
import client01 from '../../../images/client.png';
import client02 from '../../../images/client02.png';
import client03 from '../../../images/client03.png';
import client04 from '../../../images/client04.png';
import client05 from '../../../images/client05.png';
import client06 from '../../../images/client06.png';
import ScrollAnimation from 'react-animate-on-scroll';
import './Reviews.scss';
import axios from "axios"
import {isMobile, isMobileTab} from '../../jsutility/index'
import Modal from 'react-bootstrap/Modal'
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import wordsToNumbers from 'words-to-numbers';
const postsPerPage = 18;
let arrayForHoldingPosts = [];
const Reviews = (props) => {
    const [] = useState(0);

    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(18);

    const [] = useState(true);
    const [testimonials, setTestimonials] = useState([])

    const loopWithSlice = (start, end) => {
        const slicedPosts = testimonials.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
    };

    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
    };



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [reviewid, setReviewid] = useState(false);
    const formatter = buildFormatter(frenchStrings)
    const handleShow = (event) => {
        setReviewid(event);
        setShow(true);
    }
    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {
            })// could be from env files
            setTestimonials(data)
        } catch (error) {
            // cache it if fail/error;
            console.error(error)
        }
    }
    useEffect(() => {
        loopWithSlice(0, postsPerPage);
    }, [testimonials]);

    useEffect(() => {
        let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
        getitems(url);
    }, [])
    var testimonial_count = testimonials ? testimonials.length : 0;
    var rating_count = 0;
    testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
        // if(item.starRating === "ONE"){
        //     testimonial_count = testimonial_count - 1
        // }
        // else {
            rating_count += parseInt(wordsToNumbers(item.starRating));
        // }
    })
    var rating_avg = rating_count / testimonial_count;
    // const ReviewsList = [
    //     {
    //         ReviewContent: " Finding the perfect house in Dubai is a stressful process … finding the perfect agent is equally as stressful! After weeks of searching,we were very lucky...",
    //          ReviewsProfile: " Paul Vaughan",
    //          ReviewsDays: " 3 days ago",    
    //          ReviewProfileImg: client01,  
    //     },

    //     {
    //         ReviewContent: "As an owner I had interactions with Rochelle and Lee from Allsopp & Allsopp recently when my previous tenant moved out and a new one came in...",
    //          ReviewProfileImg: client02,
    //          ReviewsProfile: "Ratheesh Kumar",
    //          ReviewsDays: " 5 days ago",
    //     },

    //     {
    //         ReviewContent: "My husband and I recently rented a property through Benjamin Baker at Allsopp & Allsopp. We were under a lot a pressure to find something fast...",
    //          ReviewsProfile: " Cathy Lawless",
    //          ReviewsDays: " 1 week ago",
    //          ReviewProfileImg: client03,    
    //     },

    //     {
    //         ReviewContent: "They have some great listings, and a lot of the properties become available with them first before they're listed anywhere else. Special shout out to Robert who's...",
    //          ReviewsProfile: " Candice Walters",
    //          ReviewsDays: " 12 days ago",
    //          ReviewProfileImg: client04,
    //     },

    //     {
    //         ReviewContent: " Thank you so much Ms. Amy Stone, it was a pleasure working with you. Amy did a fantastic job. It was very efficient!",
    //          ReviewsProfile: "Imane Moulfi",
    //          ReviewsDays: " 2 weeks ago ",
    //          ReviewProfileImg: client05,
    //     },

    //     {
    //         ReviewContent: "They have some great listings, and a lot of the properties become available with them first before they're listed anywhere else. Special shout out to Robert who's...",
    //          ReviewsProfile: "Abhinav Bakshi",
    //          ReviewsDays: " 1 month ago",
    //          ReviewProfileImg: client06,
    //     },
    // ];

    // let [reviewList, setCustomerReview] = useState([]);
    // let intialNumberOfReview = 6;

    // useEffect(() => {
    //     if(isMobileTab() || isMobile()) intialNumberOfReview = 2
    //     },[]) 

    // useEffect(() => {
    //       setCustomerReview(ReviewsList.slice(0, intialNumberOfReview));
    //     }, []);
        
    return (testimonials && testimonials.length > 0 ?
        <React.Fragment>
            <section className="google-reviews">
                <ScrollAnimation animateOnce={true} animateIn='fadeIn'>

                    <Container>
                        <Row>
                            <Col>
                                <div className="reviews-info">
                                    <span className="sm-text">
                                        google reviews
                               </span>
                                    <div className="content-info">
                                        <h2>{props.Title}</h2>
                                        {/* <p className="d-none d-md-block d-lg-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus interdum ut ipsum sit amet ultrices uisque.</p> */}
                                        <div className="reviews-content">
                                            <div className="img">
                                                <picture>
                                                    <source media="(min-width:768px)" srcSet={reviews02} alt="review" />
                                                    <img src={reviews02} alt="reviews" />                              
                                                </picture>
                                            </div>
                                            <div className="rated">
                                                Rated <span className="total">{parseFloat(rating_avg).toFixed(1)}/5</span>
                                                 {/* from <span className="total">{testimonial_count} Reviews</span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                        <Col>
                        <div class="card-columns">
                        {postsToShow.map((review) => {
                                            //if(review.comment) {
                                            if (review.starRating === "FOUR" || review.starRating === "FIVE" ) {
                                                return <div class="card">
                            {/* <Col md={12} lg={12}> */}
                                <div className="reviews-box">
                                {review.starRating === "FIVE" &&
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                                            }
                                    {review.starRating === "FOUR" &&
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                                            }
                                    {review.comment &&
                                    <p className="text-content">
                                    {review.comment && review.comment.substring(0, 100)}
                                                                {review.comment && review.comment.length > 100 ? '... ' : ''}
                                                                {review.comment && review.comment.length > 100 &&
                                                                    <a href="javascript:void(0);" className="read-more" onClick={() => handleShow(review.id)}>More</a>
                                                                }
                                      </p>
                                            }
                                    <div className="feedback">
                                        <div className="client-img">
                                            <picture>
                                                <img src={review.reviewer.profilePhotoUrl}  alt={review.reviewer.displayName} />
                                            </picture>
                                        </div>
                                        <div className="client-info">
                                            <h2>{review.reviewer.displayName}</h2>
                                            <span className="timing">                                                                        <TimeAgo date={review.createTime} formatter={formatter} /> </span>
                                        </div>
                                    </div>
                                </div>
                                {reviewid && reviewid == review.id &&
                                                                <>
                                                                    <Modal
                                                                        show={show}
                                                                        size="lg"
                                                                        centered={true}
                                                                        onHide={handleClose}
                                                                        backdrop="static"
                                                                        keyboard={false}
                                                                        dialogClassName="modal-90w modal-form reviewPopup">
                                                                        <div className="review-wrap">
                                                                            <Modal.Header closeButton className="contact-close-btn">
                                                                                <Modal.Title className="w-100">{review.reviewer.displayName}
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                            <div className="reviews-box">
                                                                                <span className="review-date">
                                                                                    <TimeAgo date={review.createTime} formatter={formatter} />
                                                                                </span>
                                                                                {review.starRating === "FIVE" &&
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                                            }
                                    {review.starRating === "FOUR" &&
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                                            }
                                                                                <div className="review-desc">
                                                                                    {review.comment}
                                                                                </div>
                                                                                </div>
                                                                            </Modal.Body>
                                                                        </div>
                                                                    </Modal>
                                                                </>
                                                            }
                            {/* </Col> */}
                            </div>
                                            }
                                        })}
                                        </div>
                                        </Col>
                            {/* <Col lg={4}>
                                <div className="reviews-box">
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">
                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">
                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">
                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                                
                                    <p>
                                        As an owner I had interactions with Rochelle and Lee from Allsopp & Allsopp recently when my previous tenant moved out and a new one came in... More
                                      </p>
                                    <div className="feedback">
                                        <div className="client-img">
                                            <picture>
                                                <img src={client02} alt="client" />
                                            </picture>
                                        </div>
                                        <div className="client-info">
                                            <h2>Paul Vaughan</h2>
                                            <span className="timing">3 days ago</span>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col lg={4} className="d-none d-lg-block">
                                <div className="reviews-box">
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                             
                                    <p>
                                        Finding the perfect house in Dubai is a stressful process … finding the perfect agent is equally as stressful! After weeks of searching,
                                        we were very lucky... More
                                      </p>
                                    <div className="feedback">
                                        <div className="client-img">
                                            <picture>
                                                <img src={client03} alt="banner" />
                                            </picture>
                                        </div>
                                        <div className="client-info">
                                            <h2>Paul Vaughan</h2>
                                            <span className="timing">3 days ago</span>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}
                            {/* {testimonials.length}{postsToShow.length} */}
{testimonials.length !== postsToShow.length &&
<div className="review-load">
                                            <button href="javascript:void(0);" className="btn-pagination btn btn-primary d-md-inline-flex" onClick={handleShowMorePosts}>Load more</button>
                                        </div>
} 
                        </Row>

                        {/* <Row className="feedback-row d-none d-lg-flex">
                            <Col lg={4}>
                                <div className="reviews-box">
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                              

                                    <p>
                                        Finding the perfect house in Dubai is a stressful process … finding the perfect agent is equally as stressful! After weeks of searching,
                                        we were very lucky... More
                                      </p>
                                    <div className="feedback">
                                        <div className="client-img">
                                            <picture>
                                                <img src={client04} alt="banner" />
                                            </picture>
                                        </div>
                                        <div className="client-info">
                                            <h2>Paul Vaughan</h2>
                                            <span className="timing">3 days ago</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="reviews-box">
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">
                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">
                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">
                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                            
                                    <p>
                                        As an owner I had interactions with Rochelle and Lee from Allsopp & Allsopp recently when my previous tenant moved out and a new one came in... More
                                      </p>
                                    <div className="feedback">
                                        <div className="client-img">
                                            <picture>
                                                <img src={client05} alt="banner" />
                                            </picture>
                                        </div>
                                        <div className="client-info">
                                            <h2>Paul Vaughan</h2>
                                            <span className="timing">3 days ago</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="reviews-box">
                                    <ul className="all-ratings">
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                        <li>
                                            <i className="icon-star">

                                            </i>
                                        </li>
                                    </ul>
                                 
                                    <p>
                                        Finding the perfect house in Dubai is a stressful process … finding the perfect agent is equally as stressful! After weeks of searching,
                                        we were very lucky... More
                                      </p>
                                    <div className="feedback">
                                        <div className="client-img">
                                            <picture>
                                                <img src={client01} alt="banner" />
                                            </picture>
                                        </div>
                                        <div className="client-info">
                                            <h2>Paul Vaughan</h2>
                                            <span className="timing">3 days ago</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row> */}

                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment> :     
        <section className="google-reviews">
        <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading"><div className="dot-pulse"></div></div>
      </Container>

    </div>
    </section>
    )
};
export default Reviews;