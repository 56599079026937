import React, { useEffect, useState } from "react"
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "@StarberryUtils"
import ScrollAnimation from 'react-animate-on-scroll'
import img01 from '../../../images/contact/mob-img01.png';
import client01 from '../../../images/image01.png';
import tab01 from '../../../images/contact/tab-img.png';
import desk01 from '../../../images/contact/desk-img.png';
import ReactMarkdown from "react-markdown"
import { useStaticQuery, graphql } from "gatsby";
import CareersForm from "@Components/forms/career";
import './CareersForm.scss';
import CandidateDetailForm from "@Components/forms/candidate-detail";
import EmployerDetailForm from "@Components/forms/employer-detail";
import TalentForm from "@Components/forms/talent";
import RecordRTC from 'recordrtc'
import Video from "./Video";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const CareersFormPage = (props) => {
  const [videoblob, setvideoblob] = useState(null)
  const [videotrue, setvideotrue] = useState(false)
  const data = useStaticQuery(graphql`
    query{
  glstrapi {
    contactUs(publicationState: LIVE) {
      Content
      Title
      Image {
        alternativeText
        url
      }
    }
    recordVideo(publicationState: LIVE) {
      Content
      Title
      Image {
        url
      }
    }
  }
}
  `);
  
  // console.log(videoblob)

  return (
    <InView threshold={0.2}>
      {({ ref, inView }) => {
        return (
          <motion.section className="contact-us"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
            id={props.collection === 'Talent' ? "form" : ""}
          >
            <Container>
              <motion.div variants={contentVariants}>
                <Row>
                  <Col sm={12} lg={6} xl={7} className="p-xxl-0">
                    <div className="career-titl">
                      <sm className="sm-heading">
                        {props.collection === 'Candidate' ? '' : props.collection === 'Employer' ? '' : props.collection === 'Careers' ? 'send us your details' : null}
                      </sm>
                      <h2>{props?.Title}</h2>
                      <ReactMarkdown source={props?.Content} allowDangerousHtml />

                      {props.collection === 'Candidate' ? <CandidateDetailForm /> : props.collection === 'Employer' ? <EmployerDetailForm /> : props.collection === 'Careers' ? <CareersForm videotrue={videotrue} videoblob={videoblob} /> : props.collection === 'Talent' ? <TalentForm /> : <></>}
                    </div>
                  </Col>

                  {props.collection === 'Careers' ?
                    <Video setvideotrue={setvideotrue} setvideoblob={setvideoblob} data={data} /> : <Col sm={12} lg={6} xl={4} className="ml-lg-auto">
                    <div className="feedback">
                        <ScrollAnimation animateOnce={true} animateIn='fadeInRight'>
                            <picture className="img-zoom">
                                <source media="(min-width:992px)" srcSet={data?.glstrapi?.contactUs?.Image?.url} alt={data?.glstrapi?.contactUs?.Image?.alternativeText} />
                                <source media="(min-width:768px)" srcSet={data?.glstrapi?.contactUs?.Image?.url} alt={data?.glstrapi?.contactUs?.Image?.alternativeText} />
                                <img src={data?.glstrapi?.contactUs?.Image?.url} alt={data?.glstrapi?.contactUs?.Image?.alternativeText} />
                            </picture>
                            {/* <div className="feedback-content">
                                <ul className="rating">
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                    <li>
                                        <i className="icon-star"></i>
                                    </li>
                                </ul>
                                <p>Quisque bibendum nulla quis eros lacinia ultrices in ut nisi.
                                    Mauris congue elementum tortor eu finibus.</p>
                                <div className="client-details">
                                    <div className="clients-img">
                                        <img src={client01} alt="img01" />

                                    </div>
                                    <div className="about-client">
                                        <h5>
                                            Paul Vaughan
                                        </h5>
                                        <span className="time-info">
                                            3 days ago
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </ScrollAnimation>
                    </div>
                </Col>}
                </Row>
              </motion.div>
            </Container>
          </motion.section>
        )
      }}
    </InView>
  )
};
export default CareersFormPage;