import React, { useState, useRef } from "react"
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import AboutImg from "../../../images/advice/about.png";
import AboutImg02 from "../../../images/advice/about02.png";
import AboutImg03 from "../../../images/advice/about03.png";
import ScrollAnimation from 'react-animate-on-scroll';
import GetURL from '@Components/common/site/get-url';
import getVideoId from 'get-video-id';
import YouTube from '@u-wave/react-youtube';
import ReactMarkdown from "react-markdown"
import { Link } from "@StarberryUtils"
import './AboutUs.scss';
import { isMobile, isMobileTab } from "@Components/jsutility/index";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import { VideoPause, VideoPlayButton } from '@Components/icon/icon';
const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const AboutUs = (props) => {
    const vidRef = useRef(null);
    const [showVideo, setShowVideo] = useState(false);
    const [isPlay, setPlay] = useState(false);
    if (props.Upload_Video !== null && props.Upload_Video !== "") {
        var video_url = props.Upload_Video?.url;
        video_url = video_url ? video_url : '';
      }
      const [showHTMLVideo, setHTMLVideo] = useState(true);
      const [muteVideo, setMuteVideo] = useState(true);
      const [play, setPlayvideo] = useState(true);
      const [controls, setVideoControls] = useState(false);
      const startplayvideo = (ref) => {

        setPlayvideo(true)
        // setVideoControls(false)
        setMuteVideo(false)
      }
      const showControls = () => {
        setVideoControls(true)
      }
      const pausevideo = (ref) => {
        setPlayvideo(false)
        setVideoControls(false)
        setMuteVideo(true)
    
      }

    const [videoId, setVideoId] = useState('');
    // const [showVideo, setShowVideo] = useState(false);

    const playVideo = () => {
        var videoid = getVideoId(props?.Embed_Video_URL);
        setVideoId(videoid.id)
        setShowVideo(true)
    }
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }

    return (
        <InView threshold={0.2}>
            {({ ref, inView }) => {
                return (
                    <React.Fragment>
                        <motion.section className="about-us"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={containerVariants}
                        >
                            <Container>
                                <Row>
                                    {props.Alignment === "Right" &&
                                        <Col lg={{size :5}} className={isMobile() || isMobileTab() ? "order-1" : ""}>
                                            <ScrollAnimation animateOnce={true} animateIn='fadeInRight'>
                                                <motion.div className="about-img" variants={contentVariants}>
                                                {showHTMLVideo && play && controls && !muteVideo &&
        <div className="video-pause">
          <a onClick={() => pausevideo()}>  <VideoPause />
          </a>
        </div>
      }
                                                {showHTMLVideo &&
        <div className="html-vid">
          <ReactPlayer autoplay ref={vidRef}

            url={video_url}
            muted={muteVideo}
            loop={true}
            controls={false}
            autoplay={true}
            playsinline={true}
            playing={play}
            onEnded={() => pausevideo()}
            onClick={() => showControls()}
          />
        </div>
      }
                                                    {showHTMLVideo && !muteVideo && <div onClick={() => pausevideo()} className="close-iframe-btn"></div>}
                                                    {showVideo &&
                                                        <YouTube
                                                            video={videoId}
                                                            autoplay
                                                            onEnd={e => { setShowVideo(false) }}
                                                            modestBranding={1}
                                                            onPlaying={trackerVideo('About')}
                                                            showRelatedVideos={false}
                                                            showInfo={false}
                                                            annotations={false}

                                                        />
                                                    }
                                                    <picture>
                                                        <source media="(min-width:992px)" srcSet={props.Image.url} alt={props.Image.alternativeText} />
                                                        <source media="(min-width:768px)" srcSet={props.Image.url} alt={props.Image.alternativeText} />
                                                        <img src={props.Image.url} alt={props.Image.alternativeText} />
                                                    </picture>
                                                    {props.Embed_Video_URL && !showVideo ?
                                                        <div className="video-buttons" onClick={e => { playVideo() }}>
                                                            <strong className="video-btn">
                                                                <i className="icon-video ">
                                                                </i>
                                                            </strong>
                                                        </div>
                                                         : props.Upload_Video && muteVideo ?
                                                         <div className="video-buttons"  onClick={() => {
                                                            setHTMLVideo(true); startplayvideo();
                                                          }}>
                                                            <strong className="video-btn">
                                                                <i className="icon-video ">
                                                                </i>
                                                            </strong>
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </motion.div>
                                            </ScrollAnimation>
                                        </Col>
                                    }
                                    <Col lg={{size :7}} className={isMobile() || isMobileTab() ? "order-2" : ""}  >
                                        <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                                            <motion.div className={"about-content "+ props.Alignment} variants={titleVariants}>
                                                <h2>{props.Title}</h2>
                                                {props.Description &&<p className="big">{props.Description}</p>}
                                                <div className="para">
                                                    <ReactMarkdown source={props.Content} allowDangerousHtml />
                                                </div>
                                                {props.About_CTA?.CTA_1_Label && props.About_CTA?.CTA_1_URL &&
                                                    // <div className="content">
                                                        <GetURL label={props.About_CTA?.CTA_1_Label} alias={props.About_CTA?.CTA_1_URL?.Alias} class={"choose-us"} />
                                                        // <span className="circle">
                                                        // </span>
                                                    // </div>
                                                }
                                            </motion.div>
                                        </ScrollAnimation>
                                    </Col>
                                    {props.Alignment === "Left" &&
                                        <Col lg={{size :5}} className={isMobile() || isMobileTab() ? "order-1" : ""}>
                                            <ScrollAnimation animateOnce={true} animateIn='fadeInRight'>
                                                <motion.div className="about-img" variants={contentVariants}>
                                                {showHTMLVideo && play && controls && !muteVideo &&
        <div className="video-pause">
          <a onClick={() => pausevideo()}>  <VideoPause />
          </a>
        </div>
      }
                                                {showHTMLVideo &&
        <div className="html-vid">
          <ReactPlayer autoplay ref={vidRef}

            url={video_url}
            muted={muteVideo}
            loop={true}
            controls={false}
            autoplay={true}
            playsinline={true}
            playing={play}
            onEnded={() => pausevideo()}
            onClick={() => showControls()}
          />
        </div>
      }
                                                    {showHTMLVideo && !muteVideo && <div onClick={() => pausevideo()} className="close-iframe-btn"></div>}
                                                    {showVideo &&
                                                        <YouTube
                                                            video={videoId}
                                                            autoplay
                                                            onEnd={e => { setShowVideo(false) }}
                                                            modestBranding={1}
                                                            onPlaying={trackerVideo('About')}
                                                            showRelatedVideos={false}
                                                            showInfo={false}
                                                            annotations={false}

                                                        />
                                                    }
                                                    <picture>
                                                        <source media="(min-width:992px)" srcSet={props.Image.url} alt={props.Image.alternativeText} />
                                                        <source media="(min-width:768px)" srcSet={props.Image.url} alt={props.Image.alternativeText} />
                                                        <img src={props.Image.url} alt={props.Image.alternativeText} />
                                                    </picture>
                                                    {props.Embed_Video_URL && !showVideo ?
                                                        <div className="video-buttons" onClick={e => { playVideo() }}>
                                                            <strong className="video-btn">
                                                                <i className="icon-video ">
                                                                </i>
                                                            </strong>
                                                        </div>
                                                         : props.Upload_Video && muteVideo ?
                                                         <div className="video-buttons"  onClick={() => {
                                                            setHTMLVideo(true); startplayvideo();
                                                          }}>
                                                            <strong className="video-btn">
                                                                <i className="icon-video ">
                                                                </i>
                                                            </strong>
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </motion.div>
                                            </ScrollAnimation>
                                        </Col>
                                    }
                                </Row>
                            </Container>
                        </motion.section>
                    </React.Fragment>
                )
            }}
        </InView>
    )
};
export default AboutUs;