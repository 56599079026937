import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "@StarberryUtils"
import ScrollAnimation from 'react-animate-on-scroll'
import brand01 from '../../../images/trusted/air.png';
import Counter from './Counter';
import './About.scss';
import GetURL from '@Components/common/site/get-url';
import ReactMarkdown from "react-markdown"
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { inViewOptions } from '@Components/utils/animations';

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        delay: 0.4,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

const About = (props) => {

    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => {
                return (
                    <motion.section className="about-recruitment data"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <Row>
                                <Col sm={12} lg={6} className="p-xxl-0">
                                    <motion.div variants={titleVariants}>
                                        {/* <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'> */}
                                            <div className="recruitment">
                                                <span className="sm-heading">
                                                    {props.Title}
                                                </span>
                                                {props.Description && <p><ReactMarkdown source={props.Description} allowDangerousHtml /></p>}
                                                {/* {props.Description && <p>{props.Description}</p>} */}
                                                {props.About_CTA?.CTA_1_Label && props.About_CTA?.CTA_1_URL &&
                                                    // <div className="content">
                                                        <GetURL label={props.About_CTA?.CTA_1_Label} alias={props.About_CTA?.CTA_1_URL?.Alias} class={"choose-us"} />
                                                    //     <span className="circle">
                                                    //     </span>
                                                    // </div>
                                                    }
                                            </div>
                                        {/* </ScrollAnimation> */}
                                    </motion.div>
                                </Col>
                                <Col sm={12} lg={6} className="p-xxl-0">
                                    <motion.div className="recruitment-no" 
                                    // variants={contentVariants}
                                    >
                                        <div className="no-wrapper">
                                            {/* <ScrollAnimation animateOnce={true} animateIn='fadeIn' delay={200}> */}
                                                <div className="total-no">
                                                <Link className="link globe">
                                                        <i className="icon-globe">
                                                        </i>
                                                    </Link>

                                                    {props.Count &&
                                                        <span className="count">
                                                            <strong>{props.Count} {props.Count_Ext}</strong>
                                                            {/* <Counter
                                                                start={parseInt(props.Count)}
                                                                sym={props.Count_Ext}
                                                                duration={5}
                                                            /> */}
                                                        </span>
                                                    }
                                                    <div className="sm-content">
                                                        {props.Count_Text}
                                                    </div>
                                                </div>
                                            {/* </ScrollAnimation> */}
                                            {/* <ScrollAnimation animateOnce={true} animateIn='fadeIn' delay={300}> */}
                                                <div className="total-no">
                                                <Link className="link msg">
                                                        <i className="icon-msg">
                                                        </i>
                                                    </Link>
                                                    {props.Percentage &&
                                                        <span className="count">
                                                            {/* <Counter
                                                                start={parseInt(props.Percentage)}
                                                                sym={props.Percentage_Ext}
                                                                duration={8}
                                                                // decimal={1}
                                                            /> */}
                                                            <strong>{props.Percentage} {props.Percentage_Ext}</strong>
                                                        </span>
                                                    }
                                                    <div className="sm-content">
                                                        {props.Percentage_Text}
                                                    </div>
                                                </div>
                                            {/* </ScrollAnimation> */}
                                        </div>

                                        <div className="no-wrapper">
                                            {/* <ScrollAnimation animateOnce={true} animateIn='fadeIn' delay={400}> */}
                                                <div className="total-no">
                                                <Link className="link cube">
                                                        <i className="icon-cube">
                                                        </i>
                                                    </Link>

                                                    {props.Plus_Count &&
                                                        <span className="count">
                                                            {/* <Counter
                                                                start={parseInt(props.Plus_Count)}
                                                                sym={props.Plus_Count_Ext}
                                                                duration={10}
                                                            /> */}
                                                            <strong>{props.Plus_Count} {props.Plus_Count_Ext}</strong>
                                                        </span>
                                                    }
                                                    <div className="sm-content">
                                                        {props.Plus_Count_Text}
                                                    </div>
                                                </div>
                                            {/* </ScrollAnimation> */}
                                            {/* <ScrollAnimation animateOnce={true} animateIn='fadeIn' delay={500}> */}
                                                <div className="total-no">
                                                <Link className="link all-link">
                                                        <i className="icon-cross">
                                                        </i>
                                                    </Link>
                                                    {props.Egestas &&
                                                        <span className="count">
                                                            {/* <Counter
                                                                start={parseInt(props.Egestas)}
                                                                duration={8}
                                                                // decimal={1}
                                                                sym={props.Ext}
                                                            /> */}
                                                            <strong>{props.Egestas} {props.Ext}</strong>
                                                            {/* <span className="sub-content">AED</span> */}
                                                        </span>
                                                    }
                                                    <div className="sm-content">
                                                        {props.Egestas_Text}
                                                    </div>
                                                </div>
                                            {/* </ScrollAnimation> */}
                                        </div>
                                    </motion.div>
                                </Col>
                            </Row>
                        </Container>
                    </motion.section>
                )
            }}
        </InView>
    )
};

export default About;