import React, { useState, useEffect } from "react";
import PlayVideo from "../../Play/PlayVideo";
import { Form, Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "@StarberryUtils";
import "./Customers.scss";
import { v4 as uuidv4 } from "uuid";
import { isMobile, isMobileTab } from "../../jsutility/index";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const Customers = (props) => {
  const [videoId, setVideoId] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const [videoindex, setVideoindex] = useState(null);
  const playVideo = (id, Embed_Video_URL) => {
    var videoid = getVideoId(Embed_Video_URL);
    setShowVideo(true)
    setVideoindex(id)
    setVideoId(videoid.id)
  }
  const closePlay = () => {
    setShowVideo(false)
    setVideoId('')
    setVideoindex(null)
  }
  const trackerVideo = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }
  const data = useStaticQuery(graphql`
query{
  glstrapi {
    customers(publicationState: LIVE, where: {Publish: true}) {
      Designation
      Embed_Video_URL
      Name
      Image {
        alternativeText
        url
      }
    }
  }
}
`);
  const [isPlay, setPlay] = useState(false);

  // let [reviewList, setCustomerReview] = useState([]);
  // let intialNumberOfReview = 4;

  // useEffect(() => {
  //   if (isMobileTab() || isMobile()) intialNumberOfReview = 2;
  // }, []);

  // useEffect(() => {
  //   setCustomerReview(TeamList.slice(0, intialNumberOfReview));
  // }, []);

  return (
    <InView threshold={0.2}>
      {({ ref, inView }) => {
        return (
          <React.Fragment>
            <motion.section className="advice-team"
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={containerVariants}
            >
              <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
                <Container>
                  <Row>
                    <Col>
                      <motion.div className="about-team" variants={titleVariants}>
                        <h2>
                          {props.Title}
                        </h2>
                        <ReactMarkdown source={props.Content} allowDangerousHtml />
                      </motion.div>
                      
                      <motion.div className="team-wrapper" variants={contentVariants}>
                        {data?.glstrapi?.customers?.map((item, index) => (
                          <div className="team-information">
                            {showVideo && videoindex === index && <div onClick={(e) => { closePlay(e) }} className="close-iframe-btn"></div>}
                            <div className="team-img img-zoom">

                              {showVideo && videoindex === index &&
                                <YouTube
                                  video={videoId}
                                  autoplay
                                  showRelatedVideos={false}
                                  showInfo={false}
                                  annotations={false}
                                  onEnd={e => { closePlay(e) }}
                                  modestBranding={1}
                                  onPlaying={trackerVideo(item?.Name)}

                                />
                              }
                              <picture>
                                <source
                                  media="(min-width:992px)"
                                  srcSet={item.Image.url}
                                  alt={item.Image.alternativeText}
                                />
                                <source
                                  media="(min-width:768px)"
                                  srcSet={item.Image.url}
                                  alt={item.Image.alternativeText}
                                />
                                <img src={item.Image.url} alt={item.Image.alternativeText} />
                              </picture>
                              {showVideo && videoindex === index ? null
                                : item.Embed_Video_URL ?
                                  <div
                                    className="video-buttons"
                                    onClick={(e) => {
                                      playVideo(index, item.Embed_Video_URL);
                                    }}
                                  >
                                    <strong className="video-btn ">
                                      <i className="icon-video "></i>
                                    </strong>
                                  </div>
                                  : ''}
                            </div>

                            <div className="team-info">
                              <h4>{item.Name}</h4>
                              <span className="sm-text"> {item.Designation}</span>
                              {/* <div className="contact-wrapper">
                              <div className="contact">
                                <Link href="#">
                                  <i className="icon-call"></i>
                                </Link>
                                <a href={`tel: ${item.Phone}`}>{item.Phone}</a>
                              </div>
                              <div className="contact">
                                <Link href="#">
                                  <i className="icon-mail"></i>
                                </Link>
                                <a href={"mailto:"+ item.Email}>Email</a>
                              </div>
                              <div className="contact">
                                <Link href={item.Linked_In} target="_blank">
                                  <i className="icon-linkedln02"></i>
                                </Link>
                                <a href="#" className="d-md-none">
                                  LinkedIn
                                </a>
                              </div>
                            </div> */}
                            </div>
                          </div>
                        ))}
                      </motion.div>
                    </Col>
                  </Row>
                </Container>
              </ScrollAnimation>
            </motion.section>
          </React.Fragment>
        )
      }}
    </InView>
  )
};

export default Customers;
