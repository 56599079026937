import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils";
import GoogleReviews from "../../images/google-reviews.svg"
import "./GoogleRating.scss"
import { useStaticQuery, graphql } from "gatsby";
import { REVIEWS_PAGE_URL } from "@Components/common/site/constants";
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
const GoogleRating = (props) => {
  // const latestReviewsScore = useStaticQuery(graphql`
  //       query {
  //           allStrapiGoogleReviewReviews {
  //               edges {
  //                   node {
  //                       id
  //                       starRating
  //                   }
  //               },
  //               pageInfo {
  //                   totalCount
  //               }
  //           }
  //       }
  //   `)
  // const reviews = latestReviewsScore.allStrapiGoogleReviewReviews.edges;
  // const reviewsCount = latestReviewsScore.allStrapiGoogleReviewReviews.pageInfo.totalCount;
  // const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
  // let sumOfScores = 0;

  // reviews.map((review) => {
  //   const reviewStringValue = review.node.starRating;
  //   sumOfScores += wordToNumber[reviewStringValue];
  //   return (sumOfScores)
  // })

  // const averageRating = Math.round(sumOfScores / reviewsCount * 10) / 10;

  const [testimonials, setTestimonials] = useState([])
  const [isClient, setClient] = useState(false);
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
      })// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {

    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    getitems(url);
    setClient(true);
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
  //   if(item.starRating === "ONE"){
  //     testimonial_count = testimonial_count - 1
  // }
  // else {
      rating_count += parseInt(wordsToNumbers(item.starRating));
  // }
  })
  var rating_avg = rating_count / testimonial_count;

  return (
    testimonials && testimonials.length > 0 &&
    <Link to={REVIEWS_PAGE_URL.alias}>
    <div className="google-rating">
      <div>
        <img src={GoogleReviews} alt="Google Reviews  ALLSOPP & ALLSOPP RECRUITMENT" />
      </div>
      <p>
        Rated <strong>{parseFloat(rating_avg).toFixed(1)}/5</strong>
         {/* from {testimonial_count} Reviews */}
      </p>
    </div>
    </Link>
  )
}
export default GoogleRating
