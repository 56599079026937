import React from "react"
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "@StarberryUtils"
import ScrollAnimation from 'react-animate-on-scroll'
import brand01 from '../../../images/trusted/air.png';
import tabbrand01 from '../../../images/trusted/air02.png';
import deskbrand01 from '../../../images/trusted/air03.png';

import brand02 from '../../../images/trusted/amazon.png';
import tabbrand02 from '../../../images/trusted/amazon02.png';
import deskbrand02 from '../../../images/trusted/amazon03.png';


import brand03 from '../../../images/trusted/FedEx.png';
import tabbrand03 from '../../../images/trusted/FedEx02.png';
import deskbrand03 from '../../../images/trusted/FedEx03.png';

import deskbrand04 from '../../../images/trusted/microsoft02.png';
import deskbrand05 from '../../../images/trusted/Walmart.png';
import deskbrand06 from '../../../images/trusted/oyo.png';
import deskbrand07 from '../../../images/trusted/OLA-logo.png';
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Trusted.scss';
const Trusted = (props) => {
    const data = useStaticQuery(graphql`
    query{
    glstrapi {
        companies(publicationState: LIVE, where: {Publish: true}) {
          Image {
            alternativeText
            url
          }
        }
      }
    }
    `);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows:false,
        autoplay:true,
        mobileFirst:true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                   
                }

            },
            {
              breakpoint:767,
               settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      infinite: true,
                     
                  }
          }
          ]
      };
    return (
        <section className="trusted">
                    {/* <ScrollAnimation animateOnce={true} animateIn='fadeIn'> */}

            <Container>
                <Row>
                    <Col>

                            <div className="trusted-companies">
                                <div className="about-content">
                                    <span className="total-no">
                                        Trusted by 100+ companies
                               </span>
                                </div>
                                <div className="brands">
                                <div className="logos-blk">
                                <Slider {...settings}>
                                {data.glstrapi.companies.map((node, i) => (
                                    <div className="brand-name">
                                    <picture>
                                        <source media="(min-width:1200px)" srcSet={node.Image.url} alt={node.Image.alternativeText} />
                                        <source media="(min-width:768px)" srcSet={node.Image.url} alt={node.Image.alternativeText} />
                                        <img src={node.Image.url} alt={node.Image.alternativeText} />
                                    </picture>
                                    </div>
                                  ))}
                                  </Slider>
                                  </div>
                                    {/* <picture>
                                        <source media="(min-width:1200px)" srcSet={deskbrand02} alt="banner" />
                                        <source media="(min-width:768px)" srcSet={tabbrand02} alt="tabbrand02" />
                                        <img src={brand02} alt="amazon" />
                                    </picture>
                                    <picture>
                                        <source media="(min-width:1200px)" srcSet={deskbrand03} alt="banner" />
                                        <source media="(min-width:768px)" srcSet={tabbrand03} alt="banner" />
                                        <img className="brand03" src={brand03} alt="FedEx" />
                                    </picture> */}
                                    {/* <img className="brand04 d-none d-xl-flex" src={deskbrand04} alt="FedEx" />
                                    <img className="brand05 d-none d-xl-flex" src={deskbrand05} alt="FedEx" />
                                    <img className="brand06 d-none d-xl-flex" src={deskbrand06} alt="FedEx" />
                                    <img className="brand07 d-none d-xl-flex" src={deskbrand07} alt="FedEx" /> */}
                                </div>
                            </div>
                   </Col>
                   </Row>
            </Container>
            {/* </ScrollAnimation> */}
        </section>)

};
export default Trusted;